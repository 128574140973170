import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import PreLoader from './components/shared/PreLoader';
import ReactNotication from 'react-notifications-component';
import BaseComponent, { IProps, IState } from './base/base-component';
import NotFoundPage from './pages/NotFound';
import 'animate.css';
import './App.scss';

// Pages
const Home = React.lazy(() => import('./pages/Home'));
const ThankYou = React.lazy(() => import('./pages/ThankYou'));

interface IPosState extends IState {
  loading: boolean;
  loadingMessage?: string;
}

interface IPosProps extends IProps {}

class App extends BaseComponent<IPosProps, IPosState> {
  getInitialState() {
    return {
      loading: false,
      loadingMessage: ''
    } as IPosState;
  }

  componentDidMount() {
    this.loadIndicator.subscribe((visible: boolean) => this.setState({ loading: visible }));
  }

  render() {
    return (
      <React.Suspense fallback={<PreLoader />}>
        <ReactNotication />
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />

            <Route exact path='/thank-you' component={ThankYou} />

            <Route component={NotFoundPage} />
          </Switch>
        </Router>
        {this.state.loading && <PreLoader message={this.state.loadingMessage} />}
      </React.Suspense>
    )
  }
}

export default App;
