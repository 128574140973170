import React from 'react';
import { Link } from 'react-router-dom';
import BaseComponent, { IProps, IState } from '../../base/base-component';
import WithNavbar from '../../layouts/WithNavbar';
import './styles.scss';

interface IPosState extends IState {}

interface IPosProps extends IProps {}

class NotFound extends BaseComponent<IPosProps, IPosState> {

  constructor (props: IPosProps) {
    super(props);
    this.state = {} as IPosState;
  }

  render() {
    return (
      <WithNavbar title='Error - Not Found'>
        <div className='container-fluid text-white text-center py-4'>
          <h1>Not Found</h1>
          <Link className='btn btn-primary w-100' to='/'>Back Home</Link>
        </div>
      </WithNavbar>
    )
  }
}

export default NotFound;
