import React from 'react';
import BaseComponent, { IProps, IState } from '../base/base-component';
import Navbar from '../components/shared/Navbar';

interface IPosState extends IState {
  visible: boolean;
}

interface IPosProps extends IProps {
  title?: string,
  footer?: React.ReactNode
}

class WithNavbarAndFooter extends BaseComponent<IPosProps, IPosState> {

  constructor (props: IPosProps) {
    super(props);
    this.state = {
      visible: true
    } as IPosState;
  }

  componentWillUnmount() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <>
        <header>
          <Navbar title={this.props.title || 'Easy Mortgage'} />
        </header>

        <main className='pos-main-container'>
          {this.props.children}
        </main>
      </>
    )
  }
}

export default WithNavbarAndFooter;
