import React from 'react';
import BaseComponent, { IProps, IState } from '../../../base/base-component';
import './styles.scss';

interface IPosState extends IState {}

interface IPosProps extends IProps {
  title: string
}

class Navbar extends BaseComponent<IPosProps, IPosState> {
  render() {
    return (
      <nav className='pos-navbar'>
        <h3 className='pos-navbar__title'>{this.props.title || ''}</h3>
      </nav>
    )
  }
}

export default Navbar;
