import React from 'react';
import LoadIndicatorService, { ILoadIndicator } from '../services/loadindicator-service';

export interface IState { }

export interface IProps { }

export default class BaseComponent<P extends IProps, S extends IState> extends React.Component<P, S>{
  protected loadIndicator: ILoadIndicator = LoadIndicatorService.getInstance();
  protected showLoading = (show: boolean): any => this.loadIndicator.notify(show);

  constructor(props: P) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState(): S {
    return {} as S;
  }
}
