import React from 'react';
import './styles.scss';

const PreLoader = (props: { message?: string }) => (
  <div className='d-flex justify-content-center align-items-center flex-column pos-preloader-overlay'>
    <div className='pos-preloader-wrapper'>
      <div className='pos-preloader'></div>
    </div>
    {props.message && (<h5>{props.message}</h5>)}
  </div>
);

export default PreLoader;
